import { LoginButton } from "../components/LoginButton";
import { MainContext } from "../context/MainContext";
import { useContext } from "react";
import { Link } from "react-router-dom";
import fphome from "../images/fp-home.png";
import HuddleAvatar from "../components/Huddle/HuddleAvatar";
import DiscSvg from "../components/Assessment/DiscSvg";

import {
  Card,
  CardContent,
  Grid,
  CardActions,
  CardActionArea,
  Typography,
  Button,
  Box,
  Avatar,
} from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";

export const UserHome = () => {
  const { userInfo } = useContext(MainContext);

  return (
    <>
      {userInfo.first_name && (
        <>
          <Typography
            color="text.primary"
            variant="h4"
            component="h2"
            padding={2}
            data-testid="home-title"
          >
            Welcome {userInfo.first_name} {userInfo.last_name}
          </Typography>
          <Grid container spacing={4}>
            {userInfo.huddle !== undefined && (
              <Grid item xs={12}>
                <Card elevation={3} padding={3} sx={{ width: "100%" }}>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    <CardContent style={{ flexGrow: 1 }}>
                      <Box padding={1} alignContent="center">
                        <Typography component="h2" variant="h5" color="primary">
                          Your Huddle : {userInfo.huddle.name}
                        </Typography>
                        <Box alignContent={"center"} padding={3}>
                          <HuddleAvatar huddle={userInfo.huddle}></HuddleAvatar>
                        </Box>
                      </Box>
                    </CardContent>
                    <CardActionArea>
                      <CardActions></CardActions>
                    </CardActionArea>
                  </Box>
                </Card>
              </Grid>
            )}
            <Grid item xs={6}>
              <Card elevation={3} padding={3} sx={{ width: "100%" }}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <CardContent style={{ flexGrow: 1 }}>
                    <Box padding={1} alignContent="center">
                      <Typography component="h2" variant="h5" color="primary">
                        Your Style
                      </Typography>
                      <Box alignContent={"center"} padding={3}>
                        {userInfo.scores.computed_style === undefined && (
                          <Typography>
                            It looks like you haven't taken your assessment yet
                            to determine your style. We need to ask you some
                            questions, this should take around 5 minutes !
                            <Box p={3}>
                              <Button
                                variant="contained"
                                component={Link}
                                to="/personality/assessment"
                              >
                                Take the assessment
                              </Button>
                            </Box>
                          </Typography>
                        )}
                        {userInfo.scores.computed_style !== undefined && (
                          <DiscSvg profile={userInfo.scores}></DiscSvg>
                        )}
                      </Box>
                    </Box>
                  </CardContent>
                  <CardActionArea>
                    <CardActions>
                      {userInfo.scores.computed_style !== undefined && (
                        <Button
                          component={Link}
                          to="/personality/profile"
                          sx={{ display: "float", float: "right" }}
                        >
                          Discover more about you
                        </Button>
                      )}
                    </CardActions>
                  </CardActionArea>
                </Box>
              </Card>
            </Grid>

            {userInfo.coach !== null && (
              <Grid item xs={6} style={{ display: "flex" }}>
                <Card elevation={3} padding={3} sx={{ width: "100%" }}>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    <CardContent style={{ flexGrow: 1 }}>
                      <Box padding={1} alignContent="center">
                        <Typography component="h2" variant="h5" color="primary">
                          Your Coach
                        </Typography>
                        <Typography variant="h6">
                          {userInfo.coach.first_name} {userInfo.coach.last_name}
                        </Typography>
                        <Box alignContent={"center"} padding={3}>
                          <Avatar
                            sx={{ width: "150px", height: "150px" }}
                            src={userInfo.coach.avatar}
                          ></Avatar>
                        </Box>
                        <Typography component="h2" variant="h6" color="primary">
                          About {userInfo.coach.first_name}
                        </Typography>
                        <Typography variant="body2">
                          {userInfo.coach.coachBio}
                        </Typography>
                        {userInfo.coach.companyName && (
                          <Box marginTop={3}>
                            <Typography
                              component="h2"
                              variant="h6"
                              color="primary"
                            >
                              From : {userInfo.coach.companyName}
                            </Typography>
                            <Box alignContent={"center"} padding={3}>
                              <Avatar
                                variant="rounded"
                                sx={{ width: "200px", height: "100px" }}
                                src={userInfo.coach.companyLogo}
                              ></Avatar>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </CardContent>
                    <CardActionArea>
                      <CardActions></CardActions>
                    </CardActionArea>
                  </Box>
                </Card>
              </Grid>
            )}
            {userInfo.huddle === undefined && (
              <Grid item xs={12} style={{ display: "flex" }}>
                <Card elevation={3}>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    <CardContent style={{ flexGrow: 1 }}>
                      <Typography component="h2" color="primary" variant="h5">
                        FlyingPenguins Simulations
                      </Typography>
                      <Grid container>
                        <Grid item xs={4} padding={1}>
                          <Typography variant="body2">
                            FlyingPenguins simulations offer a unique
                            opportunity to practice essential collaboration
                            skills, such as giving and receiving feedback or
                            showing empathy, through realistic chat-based
                            conversations. Each simulation lasts between 3 to 7
                            minutes, depending on your level of engagement. The
                            more you engage, the more you’ll learn.
                          </Typography>
                          <Typography variant="body2">
                            We recommend acting as you would in a professional
                            setting when interacting with a colleague or
                            manager. Afterwards, you'll receive personalized
                            insights that highlight your strengths and areas for
                            improvement. The results of the simulations and the
                            feedback will be shared with your coach to inform
                            your development plan. To begin, simply select a
                            skill to practice and click 'Start Simulation.'
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <img
                            src={fphome}
                            style={{ width: "80%", marginLeft: "10%" }}
                          ></img>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActionArea>
                      <CardActions>
                        <Box sx={{ width: "100%" }}>
                          <Button
                            component={Link}
                            to="/simulation/options"
                            variant="contained"
                            sx={{ display: "float", float: "right" }}
                          >
                            Go to Practice
                          </Button>
                          <Button
                            component={Link}
                            to="/simulation/past"
                            sx={{ display: "float", float: "right" }}
                          >
                            Go to Past Simulations
                          </Button>
                        </Box>
                      </CardActions>
                    </CardActionArea>
                  </Box>
                </Card>
              </Grid>
            )}
            {userInfo.isCoach && (
              <Grid item xs={6} style={{ display: "flex" }}>
                <Card elevation={3} padding={3}>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    <CardContent style={{ flexGrow: 1 }}>
                      <Box padding={1}>
                        <Typography component="h2" variant="h5" color="primary">
                          Manage your users
                        </Typography>
                        <Typography variant="body2">
                          FlyingPenguins allows you to invite and understand
                          where your users are on their training journey. The
                          coaching area provides you an overview of all your
                          users and the insight engine will provide you simple
                          and actionable user insights.
                        </Typography>
                      </Box>
                    </CardContent>
                    <CardActionArea>
                      <CardActions>
                        <Button component={Link} to="/invitation">
                          Users and invitations
                        </Button>
                      </CardActions>
                    </CardActionArea>
                  </Box>
                </Card>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default UserHome;
