import { QuestionMarkSharp } from "@mui/icons-material";
import { Typography, Box } from "@mui/material";
import { useState, useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { Button } from "@mui/material";
import { Navigate } from "react-router-dom";
import AssessmentItem from "../../components/Assessment/AssessmentItem";
import axios from "axios";

function PersonalityAssessment() {
  const { keycloak } = useKeycloak();
  const [assessment, setAssessment] = useState({ items: [] });
  const [answers, setAnswers] = useState({});

  const nextItems = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "/api/assessment/disc/next", {
        headers: { Authorization: "Bearer " + keycloak.token },
      })
      .then((response) => {
        setAssessment(response.data);
      });
  };

  useEffect(() => {
    setAnswers({});
  }, [assessment]);

  const answered = (itemid, value) => {
    var l = {};
    l[itemid] = value;
    l = { ...answers, ...l };
    setAnswers(l);
    console.log("New answer", Object.keys(l).length);
  };

  useEffect(() => {
    nextItems();
  }, []);

  if (assessment.left == 0)
    return <Navigate to="/personality/profile"></Navigate>;

  return (
    <>
      <Typography variant="h5" color="primary" marginTop={3}>
        Answer these questions
      </Typography>
      {assessment.items.map((s, index) => (
        <AssessmentItem
          item={s}
          key={s.id}
          onAnswered={(v) => {
            answered(s.id, v);
          }}
        ></AssessmentItem>
      ))}
      <Box>
        {" "}
        Answered : {assessment.answered} - Remaining {assessment.left}
      </Box>

      {Object.keys(answers).length >= assessment.items.length && (
        <Box sx={{ witdh: "100%" }} p={3}>
          <Button
            data-testid="assessment-next-page"
            variant="contained"
            onClick={nextItems}
            sx={{ display: "float", float: "right" }}
          >
            Next
          </Button>
        </Box>
      )}
    </>
  );
}

export default PersonalityAssessment;
