import { Box, Card, CardContent, Typography } from "@mui/material";
import SimulationList from "../../components/Simulation/SimulationList";

function PastSimulations() {
  return (
    <Box marginTop={3}>
      <Card elevation={3}>
        <CardContent>
          <Typography variant="h5" component="h2" color="primary">
            Past Simulations
          </Typography>
          <Box padding={2}>
            <SimulationList />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

export default PastSimulations;
