import { Typography } from "@mui/material";
import { Radio, RadioGroup, Box, Card, CardContent } from "@mui/material";
import { useState } from "react";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";

function AssessmentItem({ item, onAnswered }) {
  let range = (n) => Array.from(Array(n).keys());
  const [value, setValue] = useState(null);
  const { keycloak } = useKeycloak();

  const answer = (v) => {
    setValue(v);
    axios.post(process.env.REACT_APP_API_URL + "/api/assessment/disc/answer", {
      headers: { Authorization: "Bearer " + keycloak.token },
      item_id: item.id,
      value: v,
    });
    onAnswered(v + 1);
  };

  return (
    <Box margin={3}>
      <Card elevation={3}>
        <CardContent padding={3} data-testid={item.id}>
          <Typography variant="h6" color="primary">
            {item.item}
          </Typography>
          <Typography variant="span" marginRight={3}>
            Strongly Disagree
          </Typography>
          {range(5).map((s) => (
            <Radio
              data-testid={"check_" + item.id + "_" + s}
              checked={value == s}
              onChange={(event) => {
                if (event.target.checked) {
                  answer(s);
                }
              }}
            ></Radio>
          ))}
          <Typography variant="span" marginLeft={3}>
            Strongly Agree
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}

export default AssessmentItem;
