import { Avatar, Box, Typography, Grid } from "@mui/material";
import { useState, useEffect } from "react";

const avStyle = { width: 56, height: 56 };

function HuddleAvatar({ huddle }) {
  return (
    <>
      <Grid container>
        {huddle.members.map((m, index) => {
          if (m.isCoach == false)
            return (
              <Grid item xs={2}>
                <Box>
                  <Avatar sx={avStyle} src={m.avatar}></Avatar> {m.first_name}{" "}
                  {m.last_name}
                  {m.scores != {} && (
                    <>
                      <Typography>
                        Disc:
                        <strong>{m.scores.computed_style}</strong>
                      </Typography>
                    </>
                  )}
                </Box>
              </Grid>
            );
        })}
        {huddle.invitations.map((m, index) => (
          <Grid item xs={2}>
            <Box>
              <Avatar sx={avStyle}></Avatar> (pending) {m.email}
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default HuddleAvatar;
