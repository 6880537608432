import { Typography, Box, Grid, ListItem } from "@mui/material";
import { useState, useEffect } from "react";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import { SimulationContext } from "../../context/SimulationContext";
import { Button } from "@mui/material";
import Feedback from "../../components/Feedback";
import { Navigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import RateSimulation from "../../components/RateSimulation";
import axios from "axios";
import { List } from "@mui/material";
import SimulationChat from "../../components/Simulation/SimulationChat";
import RateInsights from "../../components/RateInsights";

function SimulationInsights() {
  const { chatid } = useParams();
  const { history, loadHistory } = useContext(SimulationContext);
  const [go, setGo] = useState(0);
  const [feedback, setFeedback] = useState(0);
  const [feedbackError, setFeedbackError] = useState(0);

  function get_feedback(chatid) {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          "/api/simulation/" +
          chatid +
          "/feedback"
      )
      .then((response) => {
        setFeedbackError(false);
        setFeedback(response.data);
      })
      .catch((error) => {
        setFeedbackError(feedbackError + 1);
        if (feedbackError < 4) {
          get_feedback(chatid);
        }
      });
  }

  useEffect(() => {
    loadHistory(chatid);
    get_feedback(chatid);
  }, []);

  if (go) {
    return <Navigate to={"/new/sim/" + chatid + "/insights-rating"}></Navigate>;
  }

  return (
    <Box padding={3}>
      <Typography variant="h4" component="h2" mb={3}>
        Conversation Insights
      </Typography>

      {feedbackError > 0 && (
        <Box>
          <Typography>
            Error loading insights, retry ({feedbackError}) times
          </Typography>
        </Box>
      )}
      {feedback == 0 && (
        <Box marginTop={3} sx={{ minHeight: "500px" }}>
          <Typography variant="azh6">Processing Insights</Typography>
          <CircularProgress></CircularProgress>
        </Box>
      )}

      {feedback.improvement_opportunities !== undefined && (
        <>
          <Grid container>
            <Grid item xs={3}>
              <Typography variant="h5" color="primary">
                Chat History
              </Typography>

              <SimulationChat history={history} disable={true}></SimulationChat>
            </Grid>
            <Grid item xs={9} pl={3}>
              <Box sx={{ minHeight: "500px" }}>
                <Grid container>
                  <Grid item xs={10}>
                    <Typography variant="h5" color="primary">
                      Positive Aspects
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="h5" color="primary">
                      Insight Rating
                    </Typography>
                  </Grid>
                </Grid>
                <Typography>
                  <List sx={{ display: "list-item" }}>
                    {feedback.positive_aspects.map((f, index) => (
                      <ListItem key={index} sx={{ width: "100%" }}>
                        <Grid container>
                          <Grid item xs={10} pr={1}>
                            {f}
                          </Grid>
                          <Grid item xs={2}>
                            <RateInsights
                              chatid={chatid}
                              insightIndex={index}
                              insightCategory="positive_aspects"
                              currentValue={
                                feedback.likes.positive_aspects !== undefined
                                  ? feedback.likes.positive_aspects[index]
                                  : 0
                              }
                            ></RateInsights>
                          </Grid>
                        </Grid>
                      </ListItem>
                    ))}
                  </List>
                  <Typography variant="h5" color="primary">
                    Opportunities for Improvement
                  </Typography>
                  <List sx={{ display: "list-item" }}>
                    {feedback.improvement_opportunities.map((f, index) => (
                      <ListItem key={index} sx={{ width: "100%" }}>
                        <Grid container>
                          <Grid item xs={10} pr={1}>
                            {f}
                          </Grid>
                          <Grid item xs={2}>
                            <RateInsights
                              chatid={chatid}
                              insightIndex={index}
                              insightCategory="improvement_opportunities"
                              currentValue={
                                feedback.likes.improvement_opportunities !==
                                undefined
                                  ? feedback.likes.improvement_opportunities[
                                      index
                                    ]
                                  : 0
                              }
                            ></RateInsights>
                          </Grid>
                        </Grid>
                      </ListItem>
                    ))}
                  </List>
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ width: "100%" }}>
            <Button
              variant="contained"
              sx={{ float: "right" }}
              onClick={() => {
                setGo(1);
              }}
            >
              Next
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}

export default SimulationInsights;
