import { useState, useEffect } from "react";

function MiniDisc({ quarter, height, width }) {
  const [colors, setColors] = useState(['#4a4', '#f66', '#57f', '#ee3'])

  useEffect(() => {
    var lcolor = ['#fff', '#fff', '#fff', '#fff']
    lcolor[quarter] = colors[quarter]
    setColors(lcolor)
  }, [])


  return <>
    <svg width={width} height={height} viewBox="0 0 200 200">
      <g transform="translate(100,100)" stroke="#000" stroke-width="1">
        <path d="M0 0-100 0A99 99 0 0 1 0 -100Z" fill={colors[0]} />
        <path d="M0 0 0 -100 A99 99 0 0 1 0 100Z" fill={colors[1]} />
        <path d="M0 0 100 0 A99 99 0 0 1 0 100Z" fill={colors[2]} />
        <path d="M0 0 0 100 A99 99 0 0 1-100 0Z" fill={colors[3]} />
      </g>
    </svg>
  </>
}

export default MiniDisc;
