import {
  Button,
  Box,
  Typography,
  Chip,
  Avatar,
  TextField,
} from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";

function CreateHuddle() {
  const [goTo, setGoTo] = useState("");
  const [huddleName, setHuddleName] = useState("");
  const [currentEmail, setCurrentEmail] = useState("");
  const [huddleMembers, setHuddleMembers] = useState([]);

  const { keycloak } = useKeycloak();

  function delMember(m) {
    var position = huddleMembers.indexOf(m);
    if (position >= 0) {
      setHuddleMembers(
        huddleMembers.filter((p) => {
          return p !== m;
        })
      );
    }
  }

  function inviteHuddle() {
    axios
      .post(process.env.REACT_APP_API_URL + "/api/group/invitations", {
        headers: {
          Authorization: "Bearer " + keycloak.token,
        },
        name: huddleName,
        members: huddleMembers,
      })
      .then((reponse) => {
        setGoTo("/huddle/list");
      });
  }

  if (goTo != "") {
    return <Navigate to={goTo}></Navigate>;
  }
  return (
    <>
      <Typography variant="h4" component="h2" padding={3}>
        Create a Huddle
      </Typography>
      <Box>Name of the huddle</Box>
      <TextField
        value={huddleName}
        onChange={(event) => {
          setHuddleName(event.target.value);
        }}
      ></TextField>
      <Box>Member to add (email)</Box>
      <TextField
        value={currentEmail}
        onChange={(event) => {
          setCurrentEmail(event.target.value);
        }}
      ></TextField>
      <Button
        onClick={() => {
          setHuddleMembers([...huddleMembers, currentEmail]);
          setCurrentEmail("");
        }}
      >
        Add
      </Button>
      <Box>
        <Box p={3}>
          {huddleMembers.map((m, i) => (
            <>
              <Chip
                label={m}
                key={i}
                onDelete={() => {
                  delMember(m);
                }}
              ></Chip>{" "}
            </>
          ))}
        </Box>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Button
          variant="contained"
          sx={{ display: "float", float: "right" }}
          onClick={() => {
            inviteHuddle();
          }}
        >
          Create Huddle & Send Invitations
        </Button>
      </Box>
    </>
  );
}

export default CreateHuddle;
