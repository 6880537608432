import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { Box, Grid } from "@mui/material";
import { useState } from "react";
import axios from "axios";


const styleUp = {
  border: "1px solid black",
  borderRadius: "5px     0     0     5px",
  cursor: "pointer",
};
const styleDown = {
  border: "1px solid black",
  borderLeft: 0,
  borderRadius: "0    5px     5px   0px",
  cursor: "pointer",
};

const notSelectedColor = "grey";
function RateInsights({
  chatid,
  insightCategory,
  insightIndex,
  currentValue = 0,
}) {
  const [value, realSetValue] = useState(currentValue);

  const setValue = (v) => {
    axios
      .post(
        process.env.REACT_APP_API_URL +
          "/api/simulation/" +
          chatid +
          "/feedbacklike/" +
          insightCategory,
        {
          value: v,
          index: insightIndex,
        }
      )
      .then((response) => {
        realSetValue(v);
      });
  };

  return (
    <Grid container>
      <Grid item style={styleUp} padding={1}>
        <ThumbDownIcon
          sx={value == 1 ? { color: "red" } : { color: notSelectedColor }}
          onClick={() => {
            if (value == 1) {
              setValue(0);
            } else {
              setValue(1);
            }
          }}
        ></ThumbDownIcon>
      </Grid>
      <Grid item style={styleDown} padding={1}>
        <ThumbUpIcon
          onClick={() => {
            if (value == 2) {
              setValue(0);
            } else {
              setValue(2);
            }
          }}
          sx={value == 2 ? { color: "green" } : { color: notSelectedColor }}
        ></ThumbUpIcon>
      </Grid>
    </Grid>
  );
}

export default RateInsights;
