import { Typography, Box, Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import { SimulationContext } from "../../context/SimulationContext";
import { Button } from "@mui/material";
import { Navigate } from "react-router-dom";
import axios from "axios";
import { Rating, TextField } from "@mui/material";

function SimulationRating() {
  const { chatid } = useParams();
  const { history, loadHistory } = useContext(SimulationContext);
  const [go, setGo] = useState(0);
  const [ratingValue, setRatingValue] = useState(history.chatRating);
  const [ratingMessage, setRatingMessage] = useState("");
  const [sentComment, setSentComment] = useState(0);

  function sendRatingMessage() {
    axios
      .post(
        process.env.REACT_APP_API_URL +
          "/api/simulation/" +
          chatid +
          "/comment/" +
          "chat",
        { value: ratingMessage }
      )
      .then((response) => {});
  }

  function changeRating(newValue) {
    axios
      .post(
        process.env.REACT_APP_API_URL +
          "/api/simulation/" +
          chatid +
          "/rate/" +
          "chat",
        { value: newValue }
      )
      .then((response) => {
        setRatingValue(newValue);
      });
  }

  useEffect(() => {
    loadHistory(chatid);
  }, []);

  useEffect(() => {
    setRatingMessage(history.chatComment);
    setRatingValue(history.chatRating);
  }, [history]);

  if (go) {
    return <Navigate to={"/new/sim/" + chatid + "/insights"}></Navigate>;
  }

  return (
    <Box padding={3}>
      <Typography variant="h5" component="h2">
        Rate the conversation
      </Typography>
      <Box sx={{ minHeight: "500px" }}>
        <Rating
          defaultValue={history.chatRating}
          value={ratingValue}
          onChange={(event, newvalue) => {
            changeRating(newvalue);
          }}
        ></Rating>{" "}
        <Box mt={3}>
          Tell us more about your rating (how realistic, useful, thought
          provoking was the conversation and why)
        </Box>
        <Box>
          <TextField
            sx={{ width: "50%" }}
            multiline
            rows={8}
            value={ratingMessage}
            onChange={(event) => {
              setRatingMessage(event.target.value);
            }}
          ></TextField>
        </Box>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Button
          variant="contained"
          sx={{ float: "right" }}
          onClick={() => {
            sendRatingMessage();
            setGo(1);
          }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}

export default SimulationRating;
