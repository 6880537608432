import { useKeycloak } from "@react-keycloak/web";
import { useEffect } from "react";
import { Box } from "@mui/material";

const PrivateRoute = ({ children }) => {
  const { keycloak, initialized } = useKeycloak();

  const isLoggedIn = keycloak.authenticated;

  useEffect(() => {
    if (initialized && !keycloak.authenticated) {
      keycloak.login();
    }
  }, [initialized]);
  const style = {
    minHeight: "70%",
    width: "70%",
    marginLeft: "15%",
    marginTop: "5%",
    border: "1px solid black",
  };

  return isLoggedIn ? children : <></>;
};

export default PrivateRoute;
