import { AppBar } from "@mui/material";
import { Switch, Box, Toolbar, IconButton, Button } from "@mui/material";
import { Typography } from "@mui/material";
import { MenuOpen } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { AccountCircle } from "@mui/icons-material";
import { Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import { MainContext } from "../context/MainContext";
import { useContext } from "react";
import beta from "../images/beta.svg";

function NavigationBar() {
  const [anchorEl, setAnchorEl] = useState(<></>);
  const { keycloak } = useKeycloak();
  const { userInfo } = useContext(MainContext);
  const menuId = "primary-search-account-menu";
  const isMenuOpen = Boolean(anchorEl);

  function handleMenuClose() {}
  function handleProfileMenuOpen() {}

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>Logout</MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" elevation={5} color="primary">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            component={Link}
            to="/home"
          >
            <FlightTakeoffIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            FlyingPenguins{" "}
            <img
              src={beta}
              height="30px"
              style={{ filter: "invert(100%)" }}
            ></img>
          </Typography>
          {keycloak.authenticated && (
            <Box sx={{ display: { xs: "none", sm: "block", color: "white" } }}>
              <Button
                data-testid="navbar-home"
                component={Link}
                to="/home"
                variant="contained"
                sx={{ marginRight: "10px" }}
                color="primary"
              >
                Home
              </Button>
              {userInfo.isCoach && (
                <>
                  <Button
                    data-testid="navbar-users"
                    component={Link}
                    variant="contained"
                    to="/invitation"
                    sx={{ marginRight: "10px" }}
                    color="primary"
                  >
                    Users
                  </Button>
                  <Button
                    data-testid="navbar-huddles"
                    component={Link}
                    variant="contained"
                    to="/huddle/new"
                    sx={{ marginRight: "10px" }}
                    color="primary"
                  >
                    Huddles
                  </Button>
                </>
              )}

              <Button
                data-testid="navbar-style"
                component={Link}
                variant="contained"
                to="/personality/assessment"
                sx={{ marginRight: "10px" }}
                color="primary"
              >
                Your style
              </Button>
              {(userInfo.huddle == undefined || userInfo.huddle.step > 2) && (
                <Button
                  data-testid="navbar-practice"
                  component={Link}
                  variant="contained"
                  to="/simulation/options"
                  sx={{ marginRight: "10px" }}
                  color="primary"
                >
                  Your Practice
                </Button>
              )}
              {/*
              <Button
                data-testid="navbar-coach"
                component={Link}
                variant="contained"
                to="/user/coach"
                sx={{ marginRight: "10px" }}
                color="primary"
              >
                Your Coach
              </Button>

              */}
              <IconButton
                data-testid="navbar-profile"
                aria-label="account of current user"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
                color="inherit"
                component={Link}
                to="/user/profile"
              >
                <AccountCircle />
              </IconButton>

              <Switch />
              <Button
                data-testid="navbar-logout"
                color="primary"
                variant="contained"
                onClick={() => {
                  keycloak.logout();
                }}
              >
                Logout
              </Button>
            </Box>
          )}
          {!keycloak.authenticated && (
            <Button
              data-testid="navbar-login"
              variant="contained"
              onClick={() => {
                keycloak.login();
              }}
              color="primary"
            >
              Login
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default NavigationBar;
