import { Box } from "@mui/material";

function DiscSvg({ profile }) {
  const dtextstyle = {
    fontSize: "40px",
    paintOrder: "stroke",
    stroke: "white",
    strokeWidth: "2px",
  }
  return (
    <Box width="400">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="400"
        width="400"
        fill="lightgrey"
        viewbox="0 0 100 100"
      >
        <g transform="translate(200,200)" stroke="#000" stroke-width="0">
          <path d="M0 0-200 0A199 199 0 0 1 0 -200Z" fill="#4a4" />
          <path d="M0 0 0 -200 A199 199 0 0 1 0 200Z" fill="#f66" />
          <path d="M0 0 200 0 A199 199 0 0 1 0 200Z" fill="#57f" />
          <path d="M0 0 0 200 A199 199 0 0 1-200 0Z" fill="#ee3" />
        </g>
        <text x="25%" y="25%" class="small" fill="black" style={dtextstyle}>
          D
        </text>
        <text x="75%" y="25%" class="small" fill="black" style={dtextstyle}>
          I
        </text>
        <text x="25%" y="75%" class="small" fill="black" style={dtextstyle}>
          C
        </text>
        <text x="75%" y="75%" class="small" fill="black" style={dtextstyle}>
          S
        </text>

        <circle
          cx={profile.axis.x * 50 + 50 + "%"}
          cy={-profile.axis.y * 50 + 50 + "%"}
          r="2%"
          fill="black"
          stroke="white"
        ></circle>

      </svg>
    </Box>
  );
}

export default DiscSvg;
